import React from "react";
import PropTypes from "prop-types";
import BookingForm from "../../components/booking-form";

const BookingSection = ({ data }) => {
    return (
        <section className="booking-section section-space-pb border-b">
            <div className="container-full">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
                    <div className="left-area md:col-span-5">
                        <div className="content sm:max-w-[360px]">
                            {data?.texts && (
                                <p className="dec">
                                    {data?.texts?.[0]?.content}
                                </p>
                            )}
                            {data?.items && (
                                <div className="opening-time my-12">
                                    <h4>Note</h4>
                                    <ul>
                                        {data?.items.map((item) => (
                                            <li
                                                key={item.id}
                                                className="text-black mb-2 last:mb-0"
                                            >
                                                <strong>{item.title}:</strong>{" "}
                                                {item.description}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {data?.texts && (
                                <div className="booking-sidebar-policy">
                                    <h4 className="mb-5">
                                        Cancellation policy
                                    </h4>
                                    {data?.texts && (
                                        <p className="dec">
                                            {data?.texts?.[1]?.content}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="right-area md:col-span-7">
                        <div className="booking-form">
                            <BookingForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

BookingSection.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
    }),
};

export default BookingSection;
