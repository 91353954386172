import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../shared/button";

const BookingForm = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    return (
        <form action="#">
            <div className="grid grid-cols-2 gap-5">
                <div className="col-12">
                    <label
                        className="form-label block mb-1"
                        htmlFor="check-in-date"
                    >
                        CHECK-IN <span className="required">*</span>
                    </label>
                    <DatePicker
                        className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                        name="check-in-date"
                        type="text"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="d/MM/yyyy"
                        placeholderText="Select Check In Date"
                        required
                    />
                </div>
                <div className="col-12">
                    <label
                        className="form-label block mb-1"
                        htmlFor="check-out-date"
                    >
                        CHECK-OUT <span className="required">*</span>
                    </label>
                    <DatePicker
                        className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                        name="check-in-date"
                        type="text"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="d/MM/yyyy"
                        placeholderText="Select Check Out Date"
                        required
                    />
                </div>
                <div className="col-span-2">
                    <label className="form-label block mb-1" htmlFor="adults">
                        ADULTS <span className="required">*</span>
                    </label>
                    <select
                        className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                        name="adults"
                        id="adults"
                    >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>
                </div>
                <div className="col-span-2">
                    <label
                        className="form-label block mb-1"
                        htmlFor="childrens"
                    >
                        CHILDRENS (from 2-12 years old){" "}
                        <span className="required">*</span>
                    </label>
                    <select
                        className="form-control w-full bg-transparent border border-[#CFCFD4] p-4 focus:outline-none"
                        name="childrens"
                        id="childrens"
                    >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div className=" col-span-2 mt-6">
                    <Button
                        color="primary"
                        hover="hoverDark"
                        type="submit"
                        className="px-16"
                    >
                        Booking Now
                    </Button>
                </div>
            </div>
        </form>
    );
};

BookingForm.propTypes = {};

export default BookingForm;
